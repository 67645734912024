<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from '@/Plugins/axios'
import { useStore } from 'vuex'

import { useCache } 	from '@/composables/useCache'
import { useApiURL } 	from '@/composables/useApiURL'
import NodeCard 		from '@/views/Node/NodeCard.vue'
import NodeDetailView 	from '@/views/Node/NodeDetailView.vue'

const store = useStore()
const { getApiURL } 	= useApiURL()
const { storeCache, getCache } = useCache()

const isLoading 	= ref(false)
const nodes 		= ref([])
const selectedNode  = ref(null)
const searchQuery 	= ref('')
const selectedType  = ref('all')
const isDetailView  = ref(false)
const isLeaving 	= ref(false)

const user = computed(() => store.state.user)

// 节点类型选项
const typeOptions = [
	{ value: 'all', label: '全部节点', icon: 'fa-server' },
	{ value: 'ssr', label: 'SSR', icon: 'fa-shield-alt' },
	{ value: 'trojan', label: 'Trojan', icon: 'fa-rocket' },
]

// 过滤节点
const filteredNodes = computed(() => {
	if (nodes.value.length === 0) return []
	
	return nodes.value.filter(node => {
		const matchesSearch = node.name.toLowerCase().includes(searchQuery.value.toLowerCase())
		const matchesType = selectedType.value === 'all' || node.type.toLowerCase() === selectedType.value
		return matchesSearch && matchesType
	})
})

// 处理节点点击
const handleNodeClick = (node) => {
	if (!node.online) {
		return
	}

	let config;
	if (node.type === 'trojan') {
		config = {
			'连接密码': node.config.password,
			'SNI': node.config.sni,
			'跳过验证': node.config.allow_insecure,
		}
	} else if (node.type === 'ssr') {
		// 确保 params 对象存在且包含必要属性
		const params = node.config.params || {}
		config = {
			'加密': node.config.cipher,
			'协议': node.config.protocol,
			'混淆': node.config.obfs,
			'混淆参数': params.obfs || '异常',
			'协议参数': params.protocol || '异常',
		}
	}

	if (config) {
		node.parsedConfig = config
	}

	selectedNode.value = node
	isDetailView.value = true
}

// 返回列表视图
const handleBack = async () => {
	isLeaving.value = true
	await new Promise(resolve => setTimeout(resolve, 300))
	isDetailView.value = false
	selectedNode.value = null
	isLeaving.value = false
}

// 获取节点数据
const getNodes = async () => {
	isLoading.value = true

	const cachedNodes = getCache('nodes')
	if (cachedNodes) {
		nodes.value = cachedNodes
		isLoading.value = false
		return
	}

	try {
		const res = await axios.get(getApiURL('nodes'))
		// 排序：先按在线状态排序，再按名称排序
		res.data.sort((a, b) => {
			// 如果在线状态不同，在线的排前面
			if (a.online !== b.online) {
				return b.online - a.online
			}
			// 如果在线状态相同，按名称排序
			return a.name.localeCompare(b.name)
		})
		
		nodes.value = res.data
		storeCache('nodes', res.data, 86400)
	} catch (error) {
		console.error(error)
	} finally {
		isLoading.value = false
	}
}

// 获取可用节点数量
const availableNodesCount = computed(() => {
	return filteredNodes.value.filter(node => node.available).length
})

onMounted(() => {
	if (!user.value) {
		store.dispatch('getUserData')
	}

	getNodes()
})
</script>

<template>
	<div class="container-fluid py-4">
		<!-- 节点列表视图 -->
		<div v-show="!isDetailView" 
			 :class="{ 'fade-enter-active': !isDetailView && !isLeaving }">
			<!-- 搜索和过滤区 -->
			<div class="row mb-4">
				<div class="col-12">
					<div class="card">
						<div class="card-body p-3">
							<div class="row align-items-center">
								<!-- 搜索框 -->
								<div class="col-lg-5">
									<div class="input-group input-group-sm">
										<span class="input-group-text border-0 ps-3 bg-transparent">
											<i class="fas fa-search text-primary"></i>
										</span>
										<input type="text" 
											   class="form-control border-0 px-2" 
											   v-model="searchQuery"
											   placeholder="搜索节点名称..."
											   style="background: #f8f9fa;">
									</div>
								</div>
								
								<!-- 节点类型过滤 -->
								<div class="col-lg-7">
									<div class="d-flex justify-content-end gap-2">
										<button v-for="option in typeOptions" 
												:key="option.value"
												type="button"
												class="btn btn-sm mb-0"
												:class="[
													selectedType === option.value 
														? 'btn-primary shadow-sm' 
														: 'btn-light',
													'px-3'
												]"
												@click="selectedType = option.value">
											<i class="fas" :class="option.icon"></i>
											<span class="ms-1">{{ option.label }}</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 节点列表 -->
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header p-3">
							<div class="d-flex justify-content-between">
								<div>
									<h6 class="mb-0">节点列表 
										<span class="text-sm text-muted">
											({{ availableNodesCount }}/{{ filteredNodes.length }})
										</span>
									</h6>
								</div>

								<div class="d-flex align-items-center">
									<span class="text-xs text-muted me-3">
										<i class="fas fa-circle text-success me-1"></i>
										可用节点
									</span>
									<span class="text-xs text-muted">
										<i class="fas fa-circle text-muted me-1"></i>
										等级不足
									</span>
								</div>
							</div>
						</div>
						
						<div class="card-body p-3">
							<!-- 空状态显示 -->
							<div v-if="!isLoading && filteredNodes.length === 0" 
								 class="empty-state text-center py-5">
								<div class="empty-state-icon mb-3">
									<i class="fas fa-search fa-3x text-muted"></i>
								</div>
								<h6 class="text-muted mb-1">
									{{ searchQuery ? '未找到匹配的节点' : '暂无可用节点' }}
								</h6>
								<p class="text-sm text-muted mb-0">
									{{ searchQuery 
										? '请尝试使用其他关键词搜索' 
										: availableNodesCount === 0 
											? '请升级您的套餐以使用更多节点' 
											: '请稍后再试或联系客服' }}
								</p>
								<div class="mt-3" v-if="availableNodesCount === 0 && filteredNodes.length > 0">
									<button class="btn btn-sm btn-primary" @click="$router.push('/user/plan')">
										<i class="fas fa-arrow-up me-2"></i>
										升级套餐
									</button>
								</div>
							</div>

							<!-- 节点列表 -->
							<TransitionGroup 
								v-else
								name="node-list" 
								tag="div"
								class="row g-3"
							>
								<div v-for="node in filteredNodes" 
									 :key="node.id" 
									 class="col-md-6 col-lg-4">
									<NodeCard
										:node="node" 
									 	:UserLevel="user?.level || 0"
									 	@click="handleNodeClick(node)" />
								</div>
							</TransitionGroup>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 节点详情视图 -->
		<div class="mt-md-6 mt-0" v-show="isDetailView" 
			 :class="{ 
				 'fade-enter-active': isDetailView && !isLeaving,
				 'fade-leave-active': isLeaving 
			 }">
			<NodeDetailView
				:node="selectedNode"
				@back="handleBack"
				v-if="selectedNode"
			/>
		</div>

		<!-- 加载状态 -->
		<div v-if="isLoading" class="row">
			<div class="col-12 text-center">
				<div class="spinner-border text-primary" role="status">
					<span class="visually-hidden">加载中...</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.fade-enter-active {
	animation: fadeIn .3s ease both;
}

.fade-leave-active {
	animation: fadeOut .3s ease both;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(-20px);
	}
}

.input-group {
	background: #f8f9fa;
	border-radius: 0.5rem;
}

.input-group:focus-within {
	box-shadow: 0 0 0 2px rgba(94, 114, 228, 0.2);
}

.btn-light {
	background-color: #f8f9fa;
	border-color: transparent;
}

.btn-light:hover {
	background-color: #e9ecef;
	border-color: transparent;
}

/* 节点列表动画 */
.node-list-move,
.node-list-enter-active,
.node-list-leave-active {
	transition: all 0.3s ease;
}

.node-list-enter-from {
	opacity: 0;
	transform: translateY(30px);
}

.node-list-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}

.node-list-leave-active {
	position: absolute;
}

.empty-state {
	min-height: 240px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	animation: fadeIn 0.3s ease;
}

.empty-state-icon {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background: #f8f9fa;
	display: flex;
	align-items: center;
	justify-content: center;
}

.empty-state-icon i {
	opacity: 0.5;
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-xs .fas.fa-circle {
	font-size: 8px;
	vertical-align: middle;
}
</style>